/* eslint-disable quotes */
export const appConfig =
{
  "apiKey": "AIzaSyB7Nb8GMOUBlN9BRQC6Au-8l5ktLsewRzQ",
  "appId": "1:763936283111:web:c056c5b682e45a381f4542",
  "authDomain": "schooldog-i-coffee-ga-2.firebaseapp.com",
  "measurementId": "G-9WVXELQ5B6",
  "messagingSenderId": "763936283111",
  "project": "schooldog-i-coffee-ga-2",
  "projectId": "schooldog-i-coffee-ga-2",
  "showGaBranding": true,
  "storageBucket": "schooldog-i-coffee-ga-2.appspot.com"
}
